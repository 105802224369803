import styled from 'styled-components';


const Feature = ({ content, image, title }) => {
    return (
        <Container>
            <div>
                <img src={image} alt={'feature'} />
            </div>
            <div>
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        </Container>
    )
};

const Container = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    padding-right: 40px;
  
  img{
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  
  h3{
    color: #e27626
  }

  @media (max-width: 1200px){
    padding-right: 10px;
  }

  @media (max-width: 880px) {
    padding: 20px 0;
  }
`

export default Feature;