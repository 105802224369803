import styled from "styled-components";
import { useMain } from "../../Context";
import { Steps } from "antd";
import { useEffect, useState } from "react";

const { Step } = Steps;

const TripStatus = () => {
  const { trackingId, data, trackerData } = useMain();
  const [currentPoint, setCurrentPoint] = useState(0);

  const mapTripStatus = (status) => {
    if (status === "ACCEPTED") {
      setCurrentPoint(0);
    }
    if (status === "ON_ROUTE") {
      setCurrentPoint(1);
    }
    if (status === "ARRIVED") {
      setCurrentPoint(2);
    }
    if (status === "TRIP_STARTED") {
      setCurrentPoint(3);
    }
    if (status === "AT_DESTINATION") {
      setCurrentPoint(4);
    }
    if (status === "OFFLOADING_STARTED") {
      setCurrentPoint(5);
    }
    if (status === "OFFLOADING_ENDED") {
      setCurrentPoint(6);
    }
    if (status === "ON_ROUTE_DROPOFF" && !data?.is_container_owner) {
      setCurrentPoint(7);
    }
    if (status === "AT_DROPOFF" && !data?.is_container_owner) {
      setCurrentPoint(8);
    }
    if (status === "FINISHED" && !data?.is_container_owner) {
      setCurrentPoint(9);
    } else if (status === "FINISHED" && data?.is_container_owner) {
      setCurrentPoint(7);
    }
  };

  useEffect(() => {
    if (trackerData?.status) {
      mapTripStatus(trackerData?.status);
    } else {
      mapTripStatus(data?.trip_status);
    }
  }, [data, trackerData]);

  return (
    <Status>
      <h2 className="">Trip Status</h2>

      <div className="container">
        <Steps current={currentPoint} size="small" labelPlacement="vertical">
          <Step title="Accepted" />
          <Step title="OnRoute" />
          <Step title="Arrived" />
          <Step title="Started" />
          <Step title="At Destination" />
          <Step title="Offload Started" />
          <Step title="Offload Completed" />
          {!data?.is_container_owner && (
            <>
              <Step title="OnRoute Drop-off" />
              <Step title="At Drop-off" />
            </>
          )}
          <Step title="Finished" />
        </Steps>
      </div>
    </Status>
  );
};

const Status = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;

  h2 {
    margin-bottom: 20px;
  }

  .container {
    padding: 10px 0px;
    overflow-x: auto;
    width: 100%;
  }
`;

export default TripStatus;
