import styled from 'styled-components';
import { BeatLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import {Button, Input} from 'antd';
import { BsTelephone } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'
import {useMain} from "../Context";
import {config} from "../config";



const Footer = ({ width }) => {
    const { setEmail, addContact, email, emailLoaded } = useMain()
    return <StyledFooter>
        <Inner width={width}>
            <Item>
                <h1>GoTruck</h1>
                <List>
                    <li className='list-content'>
                        <a href={'tel:+2348137729713'}>
                            <BsTelephone color={'#e27626'} className='icon'/> +234 813 7729 713
                        </a>
                    </li>
                    <li className='list-content'>
                        <a href={'mailto:admin@gotruck.com'}>
                            <AiOutlineMail color={'#e27626'} className='icon'/> admin@gotruck.com
                        </a>
                    </li>
                </List>
            </Item>
            <Item>
                <h3>Useful links</h3>
                <List>
                    <li>
                        <Link to={'/about-us'}>
                            About Us
                        </Link>
                    </li>
                </List>
            </Item>
            <Item>
                <h3>Company</h3>
                <List>
                    <li>
                        <Link to={'/privacy-policy'}>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to={'/terms-of-use'}>Terms of use</Link>
                    </li>
                </List>
            </Item>
            <Item>
                <h3>News Letter</h3>
                <div className={'input-button'}>
                <Input type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Enter your email'} />
                <Button type="primary" onClick={addContact}>{emailLoaded ?
                    <BeatLoader color={"#ffffff"} loading={emailLoaded} size={15} width={10} margin={3} height={12}/>
                    : "Subscribe"
                }</Button>
                </div>
            </Item>
        </Inner>
    </StyledFooter>
}

const StyledFooter = styled.div`
    min-height: 40vh;
    display: flex;
    align-items: center;
    padding-bottom: 40px;

  @media (max-width: 600px){
    padding: 40px 0;
  }
    
`

const Inner = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #344054;
    flex-wrap: wrap;
`

const Item = styled.div`
  
  .input-button{
    display: flex;
  }
    
    input{
      height: 50px;
      padding-left: 10px;
      background: white;
      border-radius: 5px;
      border: 1px darkgray solid;
      margin-right: 5px;
      flex: 1;

      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      
    }
  
  button{
    height: 50px;
    border-radius: 5px;
    width: 35%;
  }
  
  @media (max-width: 800px){
    width: 50%;
  }
  @media (max-width: 600px){
    width: 100%;
  }

`

const List = styled.ul`
    list-style: none;
    padding-left: 0;
  
    li{
      margin-bottom: 10px;
    }
  
    a{
      display: flex;
      align-items: center;
    }
  
    .icon{
      margin-right: 10px;
    }
  
  a{
    color: #344054;
  }
`

export default Footer;