import styled from 'styled-components';
import { useMain } from "../../Context";
import { Image } from 'antd';


const DriverInfo = () => {
    const { data } = useMain()
    return (
        <Content>
            <h2>Driver Info</h2>
            <div className={'image-container'}>
            <Image
                src={data?.driver_image?.original ? data?.driver_image?.original : "/empty.png"}
                fallback={'/empty.png'}
            />
            </div>
            <p>Name: {data?.driver_name ? data?.driver_name : "N/A"}</p>
            {/*<p>Phone number: N/A</p>*/}
        </Content>
    )
};


const Content = styled.div`
  
  padding: 20px;
  background: #fff;
  margin: 0 0 20px;
  border-radius: 5px;
  width: 100%;
  
  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

    .ant-image-img{
      border-radius: 5px;
      width: 200px;
    }
  p{
    margin: 0 0 5px;
  }
`

export default  DriverInfo;