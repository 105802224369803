
const environment = process.env.REACT_APP_ENV || 'default';

export const config = {
default: {
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_TRIP_DEV_SERVER,
    REACT_APP_GOTRUCK_SIGNATURE:process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_SENDGRID_API_KEY:process.env.REACT_APP_SENDGRID_API_KEY,
    REACT_APP_MAPBOX_TOKEN:process.env.REACT_APP_MAPBOX_DEV_TOKEN,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY:process.env.REACT_APP_PUBNUB_DEV_SUBSCRIBE_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY:process.env.REACT_APP_PUBNUB_DEV_PUBLISH_KEY,
},
development: {
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_TRIP_DEV_SERVER,
    REACT_APP_GOTRUCK_SIGNATURE:process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_SENDGRID_API_KEY:process.env.REACT_APP_SENDGRID_API_KEY,
    REACT_APP_MAPBOX_TOKEN:process.env.REACT_APP_MAPBOX_DEV_TOKEN,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY:process.env.REACT_APP_PUBNUB_DEV_SUBSCRIBE_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY:process.env.REACT_APP_PUBNUB_DEV_PUBLISH_KEY,
},
production: {
    REACT_APP_TRIP_SERVER: process.env.REACT_APP_TRIP_PROD_SERVER,
    REACT_APP_GOTRUCK_SIGNATURE:process.env.REACT_APP_GOTRUCK_SIGNATURE,
    REACT_APP_SENDGRID_API_KEY:process.env.REACT_APP_SENDGRID_API_KEY,
    REACT_APP_MAPBOX_TOKEN:process.env.REACT_APP_MAPBOX_PROD_TOKEN,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY:process.env.REACT_APP_PUBNUB_PROD_SUBSCRIBE_KEY,
    REACT_APP_PUBNUB_PUBLISH_KEY:process.env.REACT_APP_PUBNUB_PROD_PUBLISH_KEY,
}
}[environment];
