import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaGooglePlay } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import SecondaryButton from "../components/SecondaryButton";

const Move = ({ width = "80%" }) => {
  const navigate = useNavigate();

  return (
    <StyledMove>
      <Inner width={width}>
        <img src={"/dotted4.svg"} alt={"dotted"} className={"image-one"} />
        <Content>
          <h1>Ready to move?</h1>
          <h3>Register and get started with GoTruck</h3>
          <Buttons>
            <SecondaryButton
              text={"Google Play"}
              info={"Get it on"}
              link="https://play.google.com/store/apps/details?id=com.gotruck.user"
            >
              <FaGooglePlay size={27} />
            </SecondaryButton>
            <SecondaryButton text={"App Store"} info={"Download on the"} link="#">
              <BsApple size={27} />
            </SecondaryButton>
          </Buttons>
          {/* <h3>
            Or create account to{" "}
            <span className={"get-started"} onClick={() => navigate("/signup")}>
              get started
            </span>
          </h3> */}
        </Content>
        <img src={"./dotted5.svg"} alt={"dotted"} className={"image-two"} />
      </Inner>
    </StyledMove>
  );
};

const StyledMove = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  color: white;
`;

const Inner = styled.div`
  background: #192d46;
  color: white;
  border-radius: 10px;
  width: ${({ width }) => width};
  height: 375px;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  justify-content: space-between;

  .get-started {
    color: #e27626;
    cursor: pointer;
  }

  .image-one {
    align-self: flex-end;
    height: 105px;
    width: 105px;
  }

  .image-two {
    align-self: flex-start;
    height: 105px;
    width: 105px;
  }

  h1,
  h3 {
    color: white;
    margin-top: 5px;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 0px;
  }
  h3 {
    margin: 20px 0;
  }

  @media (max-width: 950px) {
    width: 90%;
  }

  @media (max-width: 830px) {
    .image-one {
      display: none;
    }

    .image-two {
      display: none;
    }

    h1 {
      font-size: 30px;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  align-self: center;
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 400px) {
    display: block;
  }
`;

export default Move;
