import styled from 'styled-components';
import moment from 'moment'
import { useMain } from "../../Context";
import { Table } from 'antd'
import {useEffect, useState} from "react";
import { useNavigate, useParams } from 'react-router-dom';

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
    },
    // {
    //     title: 'Vehicle ID',
    //     dataIndex: 'vehicle_id',
    //     key: 'vehicle_id',
    // },
];



const ActivityTable = () => {

    let { id } = useParams();

    const { data, trackerData, getTripWithoutNavigation } = useMain()
    const [tripActivities, setTripActivities] = useState([])

    useEffect(() => {
        getTripWithoutNavigation(id);
    }, [trackerData])


    useEffect(() => {
        const newData = data?.trip_history.map(item => {
            return {
                date: moment(item?.date).format('LLL'),
                activity: item?.activity,
                vehicle_id: item?.vehicle_id
            }
        })
        setTripActivities(newData?.reverse());
    }, [data])

    return (
        <Container>
            <h2>Trip Activities</h2>
            <Table columns={columns} rowKey={"activity"}  dataSource={tripActivities} pagination={false}/>
        </Container>
    )
}


const Container = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 5px;

  th.ant-table-cell {
    background: #fff;
  }

  th.ant-table-cell::before {
    display: none;
  }
  

  @media (max-width: 920px){
    margin-bottom: 30px;
  }
`

export default ActivityTable;