import { Outlet, Link, useNavigate, useLocation, Navigate } from 'react-router-dom'
import Navbar from "../components/Navbar";



const Main = () => {
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    )
}

export default Main;


