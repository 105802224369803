import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {Button} from "antd";


const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate()

    // console.log(location);
    return (
        <Nav>
            <div className={'content'}>
                <Link to={'/'}>
                    <img src={'/go-logo.svg'} alt={'logo'} />
                </Link>
                {location.pathname !== "/" && <Button type={'primary'} onClick={() => navigate("/")}>Track new trip</Button> }
            </div>
        </Nav>
    )
};


const Nav = styled.div`
    .content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      height: 10vh;
      
      a{
        color: #000
      }
      
      button{
        border-radius: 5px;
      }
    }
`

export default Navbar;