import { useState, useEffect } from "react";
import { lineString as makeLineString } from "@turf/helpers";
import MapboxDirectionsFactory from "@mapbox/mapbox-sdk/services/directions";
import Map, { Marker, Source, Layer } from "react-map-gl";
import PubNub from "pubnub";
import { useMain } from "../Context";
import "mapbox-gl/dist/mapbox-gl.css";
import { config } from "../config";

let pubnub = null;

const CustomMap = ({ ongoing = true }) => {
  const [viewPort, setViewPort] = useState({
    width: "90%",
    height: "70vh",
    zoom: 14,
    latitude: 6.524379,
    longitude: 3.379206,
  });
  const { locations, trackingId, setTrackerData } = useMain();
  const [route, setRoute] = useState(null);

  const [truckLocation, setTruckLocation] = useState({});
  const [truckCoord, setTruckCoord] = useState([]);

  const accessToken = config.REACT_APP_MAPBOX_TOKEN;
  const directionsClient = MapboxDirectionsFactory({ accessToken });
  const startingPoint = locations?.start;
  const destinationPoint = locations?.end;

  useEffect(() => {
    const newViewPort = { ...viewPort };
    if (locations?.start?.length > 0 && !truckLocation?.latitude) {
      newViewPort.latitude = locations.start[1];
      newViewPort.longitude = locations.start[0];

      setViewPort(newViewPort);
    } else if (truckLocation?.latitude && truckLocation?.longitude) {
      newViewPort.latitude = truckLocation?.latitude;
      newViewPort.longitude = truckLocation?.longitude;

      setViewPort(newViewPort);
    }

    if (ongoing) {
      fetchRoute();
    }
  }, [truckLocation, locations]);

  const fetchRoute = async () => {
    const starting = truckCoord?.length > 0 ? truckCoord : startingPoint;
    const destination = truckLocation?.status === "ON_ROUTE" ? startingPoint : destinationPoint;
    if (truckCoord?.length > 0 && trackingId === truckLocation.trip_id) {
    }
    const reqOptions = {
      waypoints: [{ coordinates: starting }, { coordinates: destination }],
      profile: "driving-traffic",
      geometries: "geojson",
    };
    const res = await directionsClient.getDirections(reqOptions).send();
    const newRoute = makeLineString(res?.body?.routes[0]?.geometry?.coordinates);
    setRoute(newRoute);
  };

  useEffect(() => {
    initPubNub();
    connectPubNub();
  }, [trackingId]);

  const handleMessage = (event) => {
    try {
      setTruckLocation(event.message.data);
      setTrackerData(event.message.data);
      setTruckCoord([event.message.data?.longitude, event.message.data?.latitude]);
    } catch (e) {
      console.log(e);
    }
  };

  const initPubNub = () => {
    pubnub = new PubNub({
      subscribeKey: config.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
      publishKey: config.REACT_APP_PUBNUB_PUBLISH_KEY,
      uuid: "ed412e99-d834-4265-8dc6-ae81b3cff37a",
      ssl: true,
    });
  };

  const connectPubNub = () => {
    pubnub.subscribe({ channels: [trackingId] });
    pubnub.addListener({ message: handleMessage });
  };

  return (
    <div>
      <Map
        {...viewPort}
        mapboxAccessToken={config.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        style={{ width: "90%", height: "70vh", margin: "0 auto 40px", borderRadius: "5px", overflow: "hidden" }}
        onDrag={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
        add
        attributionControl={false}
        onZoom={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
      >
        {locations?.start?.length > 0 && (
          <Marker latitude={locations?.start[1]} longitude={locations?.start[0]}>
            <img src={"/starticon.png"} alt={"start-icon"} style={{ width: "70px", height: "70px" }} />
          </Marker>
        )}

        {locations?.last_location?.length > 0 && !truckLocation?.latitude && !truckLocation.longitude && (
          <Marker
            latitude={locations.last_location[1]}
            longitude={locations.last_location[0]}
            rotation={locations?.last_location_bearing}
            bearing={locations?.last_location_bearing}
          >
            <img src={"/truck_map.png"} alt={"moving-icon"} style={{ width: "60px", height: "60px" }} />
          </Marker>
        )}

        {truckLocation?.latitude && truckLocation.longitude && (
          <Marker
            latitude={truckLocation?.latitude}
            longitude={truckLocation.longitude}
            rotation={truckLocation?.bearing}
            bearing={truckLocation?.bearing}
          >
            <img src={"/truck_map.png"} alt={"moving-icon"} style={{ width: "60px", height: "60px" }} />
          </Marker>
        )}

        {locations?.end?.length > 0 && (
          <Marker latitude={locations?.end[1]} longitude={locations?.end[0]}>
            <img src={"/stopicon.png"} alt={"stop-icon"} style={{ width: "70px", height: "70px" }} />
          </Marker>
        )}

        {route && (
          <Source id="my-data" type="geojson" data={route}>
            <Layer
              id="lineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "rgba(1,31,43,0.5)",
                "line-width": 4,
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
};

export default CustomMap;
