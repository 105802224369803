import styled from "styled-components";

const SecondaryButton = (props) => {
  return (
    <Button href={props.link} target="_blank" rel="noreferrer">
      {props.children}
      <Text>
        <p className={"info"}>{props.info}</p>
        <p className={"text"}>{props.text}</p>
      </Text>
    </Button>
  );
};

const Button = styled.a`
  width: 180px;
  height: 70px;
  display: flex;
  color: black;
  background-color: #efefef;
  align-items: center;
  justify-content: center;
  text-align: left;
  //padding: 5px 20px;
  border-radius: 5px;
  border: none;
  margin: 0 5px;

  @media (max-width: 500px) {
    width: 150px;
    height: 50px;
  }

  @media (max-width: 400px) {
    margin: 10px auto;
  }
`;
const Text = styled.div`
  margin-left: 10px;
  p {
    margin: 0;
    padding-right: 0;
  }
  .info {
    font-size: 10px;
    margin-bottom: -5px;
  }
  .text {
    //font-size: 20px;
    font-weight: bold;
    margin-top: -5px;
  }

  //@media (max-width: 500px){
  //  p{
  //    font-size: 5px;
  //  }
  //  span{
  //    font-size: 8px;
  //    font-weight: bold;
  //  }
  //}
`;

export default SecondaryButton;
