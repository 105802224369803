import { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import CustomMap from "../components/CustomMap";
import TripStatus from "../components/track/TripStatus";
import DriverInfo from "../components/track/DriverInfo";
import TripInfo from "../components/track/TripInfo";
import Footer from "../components/Footer";
import ActivityTable from "../components/track/ActivityTable";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../Context";

function Track() {
  let { id } = useParams();
  const navigate = useNavigate();
  const { getTrip, setTripId } = useMain();

  useEffect(() => {
    getTrip(id);
    setTripId(id);
  }, [id]);

  return (
    <>
      {/* <Navbar /> */}
      <Container>
        <div className={"map"}>
          <CustomMap />
        </div>
        <div className={"content"}>
          <div className={"first"}>
            <TripStatus />
            <ActivityTable />
          </div>
          <div className={"second"}>
            <DriverInfo />
            <TripInfo />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  padding: 50px 0;
  width: 100%;
  background: #fafafa;

  h2 {
    color: #344054;
  }

  .content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .first {
      width: 73%;
    }
    .second {
      width: 25%;
    }
  }

  @media (max-width: 920px) {
    .content {
      display: block;

      .first {
        width: 100%;
      }
      .second {
        width: 100%;
      }
    }
  }
`;

export default Track;
