import axios from "axios";
import {config} from "./config";


const signature = config.REACT_APP_GOTRUCK_SIGNATURE
export const axios_client = axios.create({
    baseURL: config.REACT_APP_TRIP_SERVER,
    responseType: 'json',
    timeout: 180000,
});

axios_client.interceptors.request.use(
    config => {
        config.headers.common['x-gotruck-signature'] = signature;
        return config
    },

    error => Promise.reject(error)
);


export default axios_client;