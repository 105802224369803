
import { Routes, Route, Navigate} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import { BiErrorCircle } from 'react-icons/bi';
import './App.less'
import Track from "./screens/Track";
import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import PrivacyPolicy from "./screens/PrivaryPolicy";
import TermsOfUse from "./screens/TermsOfUse";
import Main from "./screens";
import {useState} from "react";
import {MainProvider} from "./Context";
import ScrollTop from "./components/ScrollTop";



function App() {
  return (
      <MainProvider>
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path={"/"} element={<Main />}>
                  <Route path='/about-us' element={<AboutUs />} />
                  <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route path='/terms-of-use' element={<TermsOfUse />} />
                  <Route path='/track/:id' element={<Track />} />
              </Route>
          </Routes>
          <Toaster
              position="top-right"
              toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {},


                  // Default options for specific types
                  success: {
                      icon:  <IoMdCheckmarkCircleOutline size={20}/>,
                      style: { background: "#f6fef9", color: "#28ae60", border: "1px solid #28ae60"},
                  },
                  error: {
                      icon: <BiErrorCircle size={20}/>,
                      style: { background: "##fffafa", color: "#b42219", border: "1px solid #b42219"},
                  }
              }
              }
          />
          <ScrollTop />
      </MainProvider>
  )
}

export default App;
