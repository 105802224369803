import styled from 'styled-components';
import Footer from "../components/Footer";
import Feature from "../components/home/Feature";
import Banner from "../components/home/Banner";


const featureDetails = [
    {
        id: 1,
        title: "Get best price estimates",
        content: "As our family of carriers grows, we’re able to source more competitive prices and show the price to you before trips begin, we’re your best bet in this market",
        image: "./feat1.png"
    },
    {
        id: 2,
        title: "Efficient trucking",
        content: "Real-time tracking with visual overview of all active trips and proactive communication leading to less time worrying about cargo logistics and more time growing your business",
        image: "./feat2.png"
    },
    {
        id: 3,
        title: "24/7 Dedicated support",
        content: "With automated dashboards, 24/7/365 customer support and live load tracking, we’re committed to delivering the best possible service to you",
        image: "./feat3.png"
    }
]


const Home = ({ setTrackingID }) => {
    return (
        <>
            <Banner setTrackingID={setTrackingID}/>
            <Features>
                <div className={'inner'}>
                    {featureDetails.map(item => <Feature key={item.id} title={item.title} content={item.content} image={item.image}/>)}
                </div>
            </Features>
            <Footer />
        </>
    )
}

const Features = styled.div`
  background: #192d46;
  padding: 40px 0;
    .inner{
      display: flex;
      width: 85%;
      margin: 0 auto;
      color: white;
    }
  
  @media (max-width: 1000px){
    .inner{
      width: 95%;
    }
  }


  @media (max-width: 880px){
    .inner{
      display: block;
    }
  }
`

export default Home;