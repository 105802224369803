import { useState, createContext, useContext } from "react";
import { validate } from "email-validator";
import axios from "axios";
import axios_client from "../store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { config } from "../config";

const MainContext = createContext(null);

export const MainProvider = ({ children }) => {
  const [data, setData] = useState();
  const [tripId, setTripId] = useState("");
  const [email, setEmail] = useState("");
  const [emailLoaded, setEmailLoaded] = useState(false);
  const [locations, setLocations] = useState({ start: [], end: [], last_location: [] });
  const [loading, setLoading] = useState(false);

  const [trackerData, setTrackerData] = useState({});

  const navigate = useNavigate();

  const getTrip = (id) => {
    setLoading(true);

    axios_client({
      method: "POST",
      url: `/trackers/track`,
      data: { tracking_id: id },
    })
      .then((res) => {
        // console.log(res.data, "worked");
        setData(res?.data);
        setLocations({
          start: res?.data?.start_location?.coordinates,
          end: res?.data?.end_location?.coordinates,
          last_location: res?.data?.last_known_vehicle_location?.coordinates,
          last_location_bearing: res?.data?.last_known_vehicle_location?.bearing,
        });

        setLoading(false);
        navigate(`/track/${id}`);
      })
      .catch((error) => {
        // setTripId("")
        // console.log(error, "didnt work");
        setLoading(false);
        navigate("/");
        toast.error((t) => <span>Tracking failed. Please confirm tracking ID</span>, {});
      });
  };

  const getTripWithoutNavigation = (id) => {
    setLoading(true);
    axios_client({
      method: "POST",
      url: `/trackers/track`,
      data: { tracking_id: id },
    })
      .then((res) => {
        // console.log(res.data, "worked");
        setData(res?.data);
        setLocations({
          start: res?.data?.start_location?.coordinates,
          end: res?.data?.end_location?.coordinates,
          last_location: res?.data?.last_known_vehicle_location?.coordinates,
          last_location_bearing: res?.data?.last_known_vehicle_location?.bearing,
        });
      })
      .catch((error) => {
        // console.log(error, "didnt work");
      });
  };

  const addContact = () => {
    if (!email) {
      toast.error((t) => <span>Email is required!</span>, {});
      return;
    }
    if (!validate(email)) {
      toast.error((t) => <span>Kindly enter a valid email!</span>, {});
      return;
    }

    setEmailLoaded(true);
    axios({
      url: "https://api.sendgrid.com/v3/marketing/contacts",
      headers: {
        Authorization: `Bearer ${config.REACT_APP_SENDGRID_API_KEY}`,
      },
      method: "PUT",
      data: {
        contacts: [
          {
            email: email,
          },
        ],
      },
    })
      .then((res) => {
        setEmailLoaded(false);
        toast.success((t) => <span>Email saved successfully!</span>, {});
        setEmail("");
      })
      .catch((e) => {
        setEmailLoaded(false);
        toast.error((t) => <span>Error saving email!</span>, {});
      });
  };

  return (
    <MainContext.Provider
      value={{
        setTripId,
        trackingId: tripId,
        locations,
        data,
        getTrip,
        loading,
        trackerData,
        setTrackerData,
        getTripWithoutNavigation,
        setEmail,
        email,
        addContact,
        emailLoaded,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMain = () => {
  return useContext(MainContext);
};
