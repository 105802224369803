import styled from "styled-components";
import ScaleLoader from "react-spinners/ScaleLoader";
import Navbar from "../Navbar";
import { Input, Button } from "antd";
import { useMain } from "../../Context";

const Banner = () => {
	const { getTrip, setTripId, trackingId, loading } = useMain();

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			getTrip(trackingId);
		}
	};

	//626c0db8e6eb6d5f2c710880

	return (
		<Container image={"/background.png"}>
			<Navbar />
			<Content>
				<h1>Request, Manage, Track, Receive with ease</h1>
				<div className={"input-container"}>
					<Input
						onChange={(e) => setTripId(e.target.value)}
						value={trackingId}
						onKeyPress={handleKeyPress}
						placeholder="Input your tracking ID"
					/>
					<Button type={"primary"} size={"large"} onClick={() => getTrip(trackingId)}>
						{loading ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={loading}
								size={50}
								width={5}
								margin={5}
								height={20}
							/>
						) : (
							"Track"
						)}
					</Button>
				</div>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 90vh;
	background: url(${({ image }) => image}) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const Content = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		width: 55%;
		text-align: center;
		line-height: normal;
		font-size: 50px;
		color: #fff;
		margin-bottom: 50px;
	}

	.input-container {
		background: #fff;
		box-sizing: border-box;
		padding: 10px;
		width: 50%;
		border-radius: 5px;
		display: flex;

		input {
			border: none;

			&:focus {
				border-color: inherit;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}

		button {
			border-radius: 5px;
			width: 170px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	@media (max-width: 960px) {
		h1 {
			width: 80%;
			font-size: 40px;
		}
	}

	@media (max-width: 900px) {
		.input-container {
			width: 80%;
		}
	}

	@media (max-width: 450px) {
		h1 {
			font-size: 30px;
		}
	}
`;

export default Banner;
