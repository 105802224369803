import styled from 'styled-components';
import { Tooltip } from 'antd';
import { IoMdCopy } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { useMain } from "../../Context";



const TripInfo = () => {
    const { trackingId, data } = useMain();

    let { id } = useParams();


    return (
        <Content>
            <h2>Trip Info</h2>
            <div>
                <div className={'item'}>
                    <p>Tracking ID: {id ? id : trackingId ? trackingId : "N/A"}</p>
                    <Tooltip title="copy trip id">
                        <IoMdCopy color={"#e27626"} size={15} className={'icon'} onClick={() =>  navigator.clipboard.writeText(id ? id : trackingId ? trackingId : "N/A")}/>
                    </Tooltip>
                </div>
                <div className={'item'}>
                    <p>Container ID: {data?.container_id ? data?.container_id : "N/A"}</p>
                    <Tooltip title="copy container id">
                        <IoMdCopy color={"#e27626"} size={15} className={'icon'}  onClick={() =>  navigator.clipboard.writeText(data?.container_id ? data?.container_id : "N/A")}/>
                    </Tooltip>
                </div>
            </div>
        </Content>
    )
}


const Content = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 100%;
  
  h2{
    margin-bottom: 20px;
  }
  
  .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px ;
    
    p{
      font-size: 10px;
      margin: 0;
    }
    
    .icon{
      cursor: pointer;
    }
  }

  @media (max-width: 920px){
    .item{
      p{
        font-size: 15px;
      }
    }
    
  }
`

export default TripInfo